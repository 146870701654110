import React, { FC } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';
import AppleStoreButton from './AppleStoreButton';
import GooglePlayButton from './GooglePlayButton';
import { tablet, useQuery } from 'styles/breakpoints';

interface ThankYouSectionProps {
  title: string;
  subtitle: string;
  img: string;
  alt: string;
}

const Container = styled.div`
  display: flex;
  max-width: 71.25rem;
  padding: 3.5rem 1rem;
  width: 100%;
  align-items: center;
  @media ${tablet} {
    padding: 1.5rem 1rem;
    flex-direction: column;
  }
`;

const TYTextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  justify-content: center;
  width: 50%;
  @media ${tablet} {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: visible;
  width: 50%;
  @media ${tablet} {
    width: 100%;
    overflow: hidden;
  }
`;

const TYTitle = styled(Text)`
  color: #000;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 0.5rem;
  width: 100%;
  @media ${tablet} {
    font-size: 2rem;
    text-align: center;
  }
`;

const TYText = styled(Text)`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 2.5rem;
  span {
    font-weight: 600;
  }
  width: 100%;
  @media ${tablet} {
    margin-bottom: 1.5rem;
    text-align: center;
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  height: 28.125rem;
  @media ${tablet} {
    height: 23.4375rem;
  }
`;

const TYButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media ${tablet} {
    margin-bottom: 3rem;
    justify-content: center;
  }
`;

const AppleStoreButtonStyled = styled(AppleStoreButton)`
  margin-right: 1rem;
`;

const ThankYouSection: FC<ThankYouSectionProps> = ({
  img,
  subtitle,
  title,
  alt,
}) => {
  const { isTablet } = useQuery();

  return (
    <Container>
      <TYTextContainer>
        <TYTitle>{title}</TYTitle>
        <TYText>{subtitle}</TYText>
        <TYButtonsContainer>
          <AppleStoreButtonStyled />
          <GooglePlayButton />
        </TYButtonsContainer>
      </TYTextContainer>
      <ImageContainer>
        <StyledDynamicImage src={img} alt={alt} />
      </ImageContainer>
    </Container>
  );
};

export default ThankYouSection;
